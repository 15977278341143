import Link from 'next/link'

const PartnerPage = () => {
    return (
        <div className="bg-[url('/assets/images/be-a-partner-bg-min.webp')] bg-no-repeat bg-center bg-cover">
            <div className="md:py-[159px] sm:py-[109px] py-[70px] md:px-32 sm:px-16 px-7">
                <p className="text-white sm:text-7xl text-5xl font-bold mb-2">Be a Partner.</p>
                <p className="text-white text-lg sm:max-w-[60%] mb-8">Your trusted auto maintenance service partner. Here at Rapidé, we are always on the lookout for our next great franchisees. Do you have what it takes to be part of a 15 billion industry?</p>
                <Link className="text-rapide-yellow bg-black px-6 py-4 font-bold" href="/be-a-fleet-partner">
                    INQUIRE NOW
                </Link>
            </div>
        </div>
    )
}

export default PartnerPage
